import React from 'react';

import { Box, Grid } from '@material-ui/core';
import EventImageDate from './EventImageDate';
import EventInfo from './EventInfo';

function EventCard(props) {
  const {start, imgUrl, defaultImgUrl, ...rest} = props;

  return (
    <Box py={2}>
      <Grid container justify='center'>
        <Grid item xs={12} sm={5}>
          <EventImageDate month={start.month} date={start.date} time={start.time} imgUrl={imgUrl || defaultImgUrl} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <EventInfo start={start} {...rest} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventCard;