import React from 'react';

import { useRemoteConfigString } from 'reactfire';

import EventCard from '../components/EventCard';
import GeneralPageLayout from '../components/GeneralPageLayout';
import InfoSection from '../components/InfoSection';

import { connect } from 'react-redux';
import { selectCurrentEvents } from '../redux/events/events.selectors';

function EventsPage({ events }) {
  const {intro} = JSON.parse(useRemoteConfigString('Events').data);
  return (
    <GeneralPageLayout intro={intro}>
      {
        events ? 
        events.map(event => (
          <EventCard key={event.id} defaultImgUrl={intro.imgUrl} {...event}/>
        )) :
        <InfoSection title="Loading" content="The events are loading live from Google Calendar!" />
      }
    </GeneralPageLayout>
  );
}

const mapStateToProps = state => ({
  events: selectCurrentEvents(state)
});

export default connect(mapStateToProps)(EventsPage);