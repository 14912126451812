import React, { Fragment } from 'react';

import { Box, Link, Typography } from '@material-ui/core';

import StyledMarkdown from './StyledMarkdown';

function EventInfo({title, description, location, start, end}) {
  var oneDay = start.date === end.date;
  function getFormattedDate(date) {
    return date.day + ", " + date.month + " " + date.date + ", " + date.year;
  }

  return (
    <Box textAlign='left' ml={2} >
      <Typography variant='h5' gutterBottom>{title}</Typography>
      {
        oneDay ?
        <Fragment>
          <Typography variant='body2'>{getFormattedDate(start)}</Typography>
          <Typography variant='body2'>{start.time + " - " + end.time}</Typography>
        </Fragment> :
        <Fragment>
          <Typography variant='body2'>{"Begins: " + getFormattedDate(start) + " " + start.time}</Typography>
          <Typography variant='body2'>{"Ends: " + getFormattedDate(end) + " " + end.time}</Typography>
        </Fragment>
      }
      {
        location ?
        <Link 
          href={`https://www.google.com/maps/dir/?api=1&destination=${location.replaceAll(' ', '+').replaceAll(',', '')}`}
          variant='body2'
          display='block'
          target='_blank'
          rel='noreferrer' 
          color='primary' 
          gutterBottom
        >
          Directions
        </Link> :
        null
      }
      <StyledMarkdown>{description ? description : ''}</StyledMarkdown>
    </Box>
  );
}

export default EventInfo;