import React from 'react';
import { useRemoteConfigString } from 'reactfire';
import Constitution from '../components/Constitution';
import GeneralPageLayout from '../components/GeneralPageLayout';

function ConstitutionPage() {
  const {intro, articles} = JSON.parse(useRemoteConfigString('Constitution').data);
  console.log(articles);
  return (
    <GeneralPageLayout intro={intro}>
      <Constitution articles={articles} />
    </GeneralPageLayout>
  );
}

export default ConstitutionPage;