import React, { Fragment } from 'react';

import { Button, Fade, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    color: 'white',
    '&:hover': {
      backgroundColor: 'slategray'
    }
  },
}));

function NavigationMenuItem({title, path, children}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    children ? (
    <Fragment>
      <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>{title}</Button>
      <Menu
        id={`${title.toLowerCase()}-menu`}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {children.map(child => (
          <MenuItem key={title+' / '+child.title} className={classes.menuItem} onClick={handleClose}>
            {
              path[0] === '/' ?
              <Button color='primary' size='large' component={Link} to={path+child.path}>
                {child.title}
              </Button> :
              <Button color='primary' size='large' href={path}>
                {child.title}
              </Button>
            }
          </MenuItem>
        ))}
      </Menu> 
    </Fragment> ) :
    path[0] === '/' ?
    <Button className={classes.button} size='large' component={Link} to={path}>
      {title}
    </Button> :
    <Button  className={classes.button} size='large' href={path}>
      {title}
    </Button>
  );
}

export default NavigationMenuItem;