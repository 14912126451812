import React from 'react';
import { Card, Box, CardMedia, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import PageIntroActionButton from '../components/PageIntroActionButton';
import { getSizedUrl } from '../helper-functions';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: '-150px',
    position: 'relative',
    zIndex: '1',
    borderRadius: '0',
  },
  cardOverlay: {
    position: 'absolute',
    backgroundColor: 'black',
    zIndex: '2',
    opacity: '.4',
    height: '100%',
    width: '100%'
  },
  cardContent: {
    position: 'relative',
    backgroundColor: 'transparent',
    height: '100%',
    zIndex: '3',
  },
  cardMedia: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
  },
  text: {
    color: 'white',
  }
}));

function PageIntro({pretitle, title, action, imgTxt, imgUrl, introHeight}) {
  const classes = useStyles();

  // Get the 'Huge Thumbnail' size of this image
  imgUrl = getSizedUrl(imgUrl, 'h');

  return (
    <Card className={classes.card} style={{height: introHeight}}>
      <CardMedia 
        media='picture'
        alt={imgTxt}
        image={imgUrl}
        title={imgTxt}
        className={classes.cardMedia}
      />
      <Box className={classes.cardOverlay} />
      <Box 
        className={classes.cardContent} 
        display='flex' 
        flexDirection='column' 
        alignItems='center' 
        justifyContent='center'
      >
        <Typography variant='h4' className={classes.text} gutterBottom>{pretitle}</Typography>
        <Typography variant='h2' className={classes.text} gutterBottom>{title}</Typography>
        {action ? <PageIntroActionButton path={action.path}>{action.text}</PageIntroActionButton> : null}
      </Box>
    </Card>
  );
}

PageIntro.propTypes = {
  pretitle: PropTypes.string, 
  title: PropTypes.string, 
  action: PropTypes.object, 
  imgTxt: PropTypes.string, 
  imgUrl: PropTypes.string, 
  introHeight: PropTypes.number,
};

PageIntro.defaultProps = {
  title: '',
  pretitle: '',
  action: null, 
  imgTxt: '', 
  // Black background image
  imgUrl: 'https://i.imgur.com/bLREcU0.jpg',
  introHeight: 115
};

export default PageIntro;