import { createSelector } from 'reselect';

const selectEvents = state => state.events;

export const selectCurrentEvents = createSelector(
  [selectEvents],
  events => events.currentEvents
);

export const areEvents = createSelector(
  [selectCurrentEvents],
  currentEvents => currentEvents ? true : false
)