import React from 'react';
import { useParams } from 'react-router-dom';
import { 
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocDataOnce,
  useRemoteConfigString 
} from 'reactfire';

import CardLayout from '../components/CardLayout';
import GeneralPageLayout from '../components/GeneralPageLayout';
import NotFound from '../components/NotFound';

function SeriesPage() {
  const {intro: {imgUrl: defaultImgUrl}} = JSON.parse(useRemoteConfigString('Sermons').data);
  const seriesId = useParams().seriesId;

  const firestore = useFirestore();

  // Get series from firestore
  const seriesRef = firestore.doc(`playlists/${seriesId}`);
  const seriesSnapshot = useFirestoreDocDataOnce(seriesRef);

  // get videos from firestore
  const videosRef = firestore.collection(`playlists/${seriesId}/videos`);
  const videoSnapshots = useFirestoreCollectionData(videosRef);

  const series = seriesSnapshot.data;
  const videos = videoSnapshots.data;

  let intro, exists;
  if (seriesSnapshot.data.count) {
    exists = true;
    const {thumbnails: {standard: {url=''} = {url: ''}, high: {url: highUrl} = {url: ''}, maxres: {url: maxUrl} = {url: ''}}} = series;
    intro = {
      title: series.title,
      imgUrl: series.imgUrl || url || highUrl || maxUrl || defaultImgUrl,
      introHeight: 300
  }
    videos.sort((a, b) => (a.position > b.position) ? -1 : 1);
  } else {
    exists = false;
    intro = {
      title: 'Sermons',
      imgUrl: defaultImgUrl,
      introHeight: 300
    }
  }

  let cardInfo = [];
  for (let video of videos) {
    const {id, title, thumbnails: {standard: {url=''} = {url: ''}, high: {url: highUrl} = {url: ''}}, imgUrl} = video;
    cardInfo.push({
      link: `/sermons/${seriesId}/${id}`,
      imgUrl: imgUrl || url || highUrl || series.imgUrl || defaultImgUrl,
      title: title,
      id: id
    });
  }

  return (
    <GeneralPageLayout intro={intro}>
      {
        exists ?
        <CardLayout contentArray={cardInfo} /> :
        <NotFound type='series' />
      }
    </GeneralPageLayout> 
  );
}

export default SeriesPage;