import React from 'react';
import { Grid } from '@material-ui/core';
import LocationCard from './LocationCard';

function Locations({locations}) {
  return (
    <Grid item container justify='center' spacing={4}>
      {
        locations.map(location => (
          <Grid item xs={12} sm={8} md={5} key={location.title}>
            <LocationCard {...location} />
          </Grid>
        ))
      }
    </Grid>
  );
}

export default Locations;