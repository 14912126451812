import React from 'react';
import { Card, CardMedia, makeStyles } from '@material-ui/core';
import DateBox from './DateBox';
import { getSizedUrl } from '../helper-functions';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
  },
  cardImage: {
    position: 'relative',
    height: 200
  },
  date: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    opacity: '90%',
  }
}));

function EventImageDate({month, date, time, imgUrl, children}) {
  const classes = useStyles();

  imgUrl = getSizedUrl(imgUrl, 'm');

  return (
    <Card raised square className={classes.card}>
      <CardMedia
        image={imgUrl}
        className={classes.cardImage}
      />
      {children}
      <DateBox month={month} date={date} time={time} className={classes.date} />        
    </Card>
  );
}

export default EventImageDate;