import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  }
}));

function NotFound({type}) {
  const classes = useStyles();
  return (
    <div className={classes.center}>
      <Typography variant='h1' gutterBottom>Whoops!</Typography>
      <Typography variant='h2' gutterBottom>{`This is not the ${type} you are looking for.`}</Typography>
      <Typography variant='h2' gutterBottom>{`Try navigating to the ${type} you want using the menu above.`}</Typography>
    </div>
  );
}

export default NotFound;