import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { makeStyles } from '@material-ui/core';
import { useRemoteConfigString } from 'reactfire';

import { connect } from 'react-redux';
import { selectCurrentEvents } from '../redux/events/events.selectors';

import EventCarouselCard from './EventCarouselCard';

const responsive = {
  md: {
    breakpoint: { max: 3000, min: 960 },
    items: 3,
  },
  sm: {
    breakpoint: { max: 960, min: 600 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  }
};

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    width: '100%'
  }
}));

function EventCarousel({ events }) {
  const classes = useStyles();

  const {intro: {imgUrl}} = JSON.parse(useRemoteConfigString('Events').data);

  return (
    <div className={classes.carouselContainer}>
      <Carousel
        responsive={responsive}
        minimumTouchDrag={20}
      >
        {events.map(event => <EventCarouselCard key={event.id} defaultImgUrl={imgUrl} {...event} />)}
      </Carousel>
    </div> 
  );
}

const mapStateToProps = state => ({
  events: selectCurrentEvents(state)
});

export default connect(mapStateToProps)(EventCarousel);