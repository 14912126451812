import React from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import EventImageDate from './EventImageDate';

function EventCarouselCard({title, start, defaultImgUrl, imgUrl}) {
  return (
    <Box m={2}>
      <EventImageDate month={start.month} date={start.date} imgUrl={imgUrl || defaultImgUrl}>
        <CardContent>
          <Typography variant='h5' gutterBottom>{title}</Typography>
          <Typography variant='body1'>{start.month} {start.date}, {start.time}</Typography>
        </CardContent>
      </EventImageDate>
    </Box>
  );
}

export default EventCarouselCard;