import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { ReactComponent as Logo } from '../assets/church-logo.svg'
import { AppBar, Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import NavigationDrawer from '../components/NavigationDrawer';
import NavigationMenu from '../components/NavigationMenu';

const useStyles = makeStyles(theme => ({
  appBar: {
    height: '150px',
  },
  containerGrid: {
    padding: '0 5%',
    zIndex: '100'
  },
  logo: {
    height: 'min(100px, max(10vw, 50px))',
    width: 'min(100px, max(10vw, 50px))',
    padding: '10px 10px',
  },
}));

function Header() {
  const classes = useStyles();
  const mediumOrLower = useMediaQuery(theme => theme.breakpoints.down('md')) 

  return (
    <AppBar position='static' color='transparent' elevation={0} className={classes.appBar}>
      <Grid container direction='row' className={classes.containerGrid}>
        <Grid item container direction='row' justify='flex-start' xs={6} >
          <Link to='/'>
            <Logo className={classes.logo} />
          </Link>
        </Grid>
        <Grid item xs={6} container alignContent='center' justify='flex-end'>
          { mediumOrLower ? <NavigationDrawer /> : <NavigationMenu /> }
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default Header;