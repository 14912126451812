import React, { Fragment } from 'react';
import { Divider, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  box: {
    width: 66,
    padding: [[5, 0]]
  },
  divider: {
    width: 56,
    margin: 'auto'
  }
}));

function DateBox({month, date, time, className}) {
  const classes = useStyles();

  return (
    <Paper square className={`${classes.box} ${className}`}>
      <Typography variant='h6'>{month.substring(0, 3)}</Typography>
      <Typography variant='body1'>{date}</Typography>
      {
        time ? 
        <Fragment>
          <Divider className={classes.divider} />
          <Typography variant='caption'>{time}</Typography>
        </Fragment> :
        null
      }
    </Paper>
  );
}

export default DateBox;