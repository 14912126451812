/**
 * Modifies a url string to be sized according to Imgur's standard method.
 * If the original url is not from imgur or the size is not one of Imgur's 
 * standard modifiers, it is returned unmodified.
 * 
 * These are the modifiers Imgur uses to serve differently sized images.
 * 's' = small square
 * 'b' = big square
 * 't' = small thumbnail
 * 'm' = medium thumbnail
 * 'l' = large thumbnail
 * 'h' = huge thumbnail
 * 
 * @param {string} originalImgUrl A string representing a url pointing to an image on Imgur
 * @param {string} size A single character string corresponding to the Imgur size desired
 * @returns {string} the original url modified according to imgur's standard method
 */
export function getSizedUrl(originalImgUrl, size) {
  const IMGUR_SUFFIXES = ['s', 'b', 't', 'm', 'l', 'h'];
  const REGEX = /https:\/\/i\.imgur\.com\/[\w]{7,8}\.\w{3,}/;

  // If the link does not match the format from imgur or 
  // the size is not one of Imgur's standard modifiers
  // return it unchanged.
  if ( !REGEX.test(originalImgUrl) || 
      IMGUR_SUFFIXES.indexOf(size) === -1 ) {
    return originalImgUrl;
  }

  const splitIndex = originalImgUrl.lastIndexOf('.');
  let start = originalImgUrl.substring(0, splitIndex);
  const end = originalImgUrl.substring(splitIndex);

  // If the image already has a size modifier, remove it.
  if (start.length > 27 && IMGUR_SUFFIXES.indexOf(start[start.length-1]) !== -1) {
    start = start.substring(0, start.length-1);
  }

  return start + size + end;
}