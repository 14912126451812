import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  p: {
    marginBottom: theme.spacing(2),
  }
}));

function Paragraph({children}) {
  const classes = useStyles();

  return (
    <Typography variant='body1' className={classes.p}>{children}</Typography>
  );
}

export default Paragraph;