import { Typography } from '@material-ui/core';

import Link from '../markdown-replacements/Link';
import ListItem from '../markdown-replacements/ListItem';
import Paragraph from '../markdown-replacements/Paragraph';

const options = {
  overrides: {
    a: {
      component: Link,
    },
    li: {
      component: ListItem,
    },
    h1: {
      component: Typography,
      props: {
        variant: 'h1'
      }
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h2'
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h3'
      }
    },
    h4: {
      component: Typography,
      props: {
        variant: 'h4'
      }
    },
    h5: {
      component: Typography,
      props: {
        variant: 'h5'
      }
    },
    h6: {
      component: Typography,
      props: {
        variant: 'h6'
      }
    },
    p: {
      component: Paragraph,
    },
    span: {
      component: Paragraph,
    }
  }
}

export default options;