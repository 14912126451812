import React from 'react';

import { Box, Typography } from '@material-ui/core';
import StyledMarkdown from '../components/StyledMarkdown';

function InfoSection({title, content, align}) {

  return (
    <Box py={4} px={4} >
      {title ? <Typography variant='h2' gutterBottom>{title}</Typography> : null}
      <Box textAlign={align ? align : 'center'}>
        {content ? <StyledMarkdown>{content}</StyledMarkdown> : null}
      </Box>
      
    </Box>
  );
}

export default InfoSection;