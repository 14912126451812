import React, { Fragment } from 'react';
import { Divider } from '@material-ui/core';
import { nanoid } from 'nanoid';

import InfoSection from './InfoSection';
import StorySection from './StorySection';

function InfoAndStories({infoAndStories}) {
  return (
    <Fragment>
      {infoAndStories.map(section => (
        section.imgUrl ? 
        <Fragment key={nanoid()}>
          <StorySection {...section}></StorySection>
          <Divider />
        </Fragment> :
        <Fragment key={nanoid()}>
          <InfoSection {...section}></InfoSection>
          <Divider />
        </Fragment> 
      ))}
    </Fragment>
  );
}

export default InfoAndStories;