import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import MapContainer from '../components/MapContainer';
import Locations from '../components/Locations';
import Times from '../components/Times';

function LocationsAndTimes({ title, locations, times, directions }) {
  return (
    <Box py={4}>
      <Grid container justify='center' direction='column' spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h2'>{title}</Typography>
        </Grid>
        <Locations locations={locations} />
        <Times times={times} />
        <Grid item xs={12}>
          <MapContainer locations={locations}/>
          <Box pt={4}>
            <Typography variant='body1' align='center'>{directions}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LocationsAndTimes;