import React, { Fragment } from 'react';

import { useRemoteConfigString } from 'reactfire';
import NavigationMenuItem from './NavigationMenuItem';

function NavigationMenu() {
  const directory = JSON.parse(useRemoteConfigString('MenuDirectory').data);

  return (
    <Fragment>
      {directory.map(page => (<NavigationMenuItem key={page.title} {...page} />))}
    </Fragment>
  );
}

export default NavigationMenu;