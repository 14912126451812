import React from 'react';
import { Button, Card, CardContent, Link, Typography } from '@material-ui/core';

function LocationCard({title, address1, address2}) {
  return (
    <Card raised>
      <CardContent>
        <Typography gutterBottom variant="h5" component='h5'>
          {title}
        </Typography>
        <Typography variant="body1" component='p' gutterBottom>
          {address1}<br />{address2}
        </Typography>
        <Button 
          color='primary'
          variant='contained'
          component={Link}
          href={`https://www.google.com/maps/dir/?api=1&destination=${title.replaceAll(' ', '+')}+Rushford+NY`}
          target='_blank'
        >
          Get Directions
        </Button>
      </CardContent>
    </Card>
  );
}

export default LocationCard;