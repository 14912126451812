import React from 'react';
import { Link as ExternalLink, makeStyles } from '@material-ui/core';
import { Link as LocalLink } from 'react-router-dom'; 

const useStyles = makeStyles(theme => ({
  localLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }
}))

function Link({href, children}) {
  const classes = useStyles();

  return (
      href[0] === '/' ?
      <LocalLink 
        to={href} 
        className={classes.localLink}
      >
        {children}
      </LocalLink> :
      <ExternalLink 
        href={href}
        target='_blank'
        rel='noreferrer' 
        color='primary' 
      >
        {children}
      </ExternalLink>
  );
}

export default Link;