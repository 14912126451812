import React from 'react';

import {useRemoteConfigString} from 'reactfire';

import EventsSection from '../components/EventsSection';
import LocationsAndTimes from '../components/LocationsAndTimes';
import GeneralPageLayout from '../components/GeneralPageLayout';
import InfoAndStories from '../components/InfoAndStories';

function HomePage() {
  const {intro, infoAndStories, locationsAndMeetingTimes} = JSON.parse(useRemoteConfigString('Home').data);
  return (
    <GeneralPageLayout intro={intro}>
      <InfoAndStories infoAndStories={infoAndStories} />
      <LocationsAndTimes {...locationsAndMeetingTimes}/>
      <EventsSection />
    </GeneralPageLayout>
  );
}

export default HomePage;