import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  li: {
    marginBottom: theme.spacing(1),
  }
}));

function ListItem({children}) {
  const classes = useStyles();

  return (
    <Typography variant='body1' component='li' className={classes.li}>{children}</Typography>
  );
}

export default ListItem;