import React from 'react';
import { useFunctions, useRemoteConfigString } from 'reactfire';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, Icon, makeStyles, MenuItem, TextField } from '@material-ui/core';

import GeneralPageLayout from '../components/GeneralPageLayout';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const validationSchema = yup.object({
  name: yup
    .string('Enter your full name')
    .matches(/\w{2,}\s\w{2,}/, {message: 'Please enter your first and last name separated by a space.'}),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  subject: yup
    .string('Enter a subject')
    .required('The subject is required'),
  body: yup
    .string('Enter your message')
    .required('You forgot to write your message!'),
})

function ContactPage() {
  const classes = useStyles();
  const {intro, form} = JSON.parse(useRemoteConfigString('Contact').data);
  const functions = useFunctions();

  const sendFormData = async (values) => {
    const formData = {...values};
    formData.recipient = form.recipient.choices[formData.recipient];
    const submitContactForm = functions.httpsCallable('submitContactForm');
    try {
      console.log(formData);
      const response = await submitContactForm(formData);
      console.log(response);
    } catch (error) {
      console.log(error.message);
    }
  }

  const formik = useFormik({
    initialValues: {
      recipient: form.recipient.defaultChoice,
      name: '',
      email: '',
      subject: '',
      body: ''
    },
    validationSchema: validationSchema,
    onSubmit: sendFormData
  });

  const sharedInputProps = {
    fullWidth: true,
    required: true,
    margin: 'normal',
    variant: 'outlined',
    onChange: formik.handleChange,
  }

  return (
    <GeneralPageLayout intro={intro}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id='recipient'
          select
          label={form.recipient.label}
          value={formik.values.recipient}
          {...sharedInputProps}
          onChange={formik.handleChange('recipient')}
        >
          {
            Object.keys(form.recipient.choices).map((option, index) => (
              <MenuItem key={option} value={option} name={option}>
                {option}
              </MenuItem>
            ))
          }
        </TextField>
        <TextField
          id='name'
          label={form.name.label}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          {...sharedInputProps}
        />
        <TextField
          id='email'
          type='email'
          label={form.email.label}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          {...sharedInputProps}
        />
        <TextField
          id='subject'
          label={form.subject.label}
          value={formik.values.subject}
          error={formik.touched.subject && Boolean(formik.errors.subject)}
          helperText={formik.touched.subject && formik.errors.subject}
          {...sharedInputProps}
        />
        <TextField
          id='body'
          label={form.body.label}
          multiline
          rows={8}
          value={formik.values.body}
          error={formik.touched.body && Boolean(formik.errors.body)}
          helperText={formik.touched.body && formik.errors.body}
          {...sharedInputProps}
        />
        <Button 
          variant='outlined'
          endIcon={<Icon>send</Icon>}
          className={classes.button}
          type='submit'
        >
          Submit
        </Button>
      </form>
    </GeneralPageLayout>
  );
}

export default ContactPage;