import React from 'react';

import { useRemoteConfigString } from 'reactfire';

import GeneralPageLayout from '../components/GeneralPageLayout';
import InfoAndStories from '../components/InfoAndStories';

function InfoAndStoryPage({configString}) {
  const {intro, infoAndStories} = JSON.parse(useRemoteConfigString(configString).data);
  return (
    <GeneralPageLayout intro={intro}>
      <InfoAndStories infoAndStories={infoAndStories} />
    </GeneralPageLayout>
  );
}

export default InfoAndStoryPage;