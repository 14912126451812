import EventsActionTypes from "./events.types";

const INITIAL_STATE = {
  currentEvents: null
}

const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EventsActionTypes.SET_CURRENT_EVENTS:
      return {
        ...state,
        currentEvents: action.payload
      }
    default:
      return state;
  }
}

export default eventsReducer;