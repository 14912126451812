import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ContentCard from './ContentCard';

function LinkedContentCard(props) {
  const { link, ...rest } = props;

  return (
    <Link to={link} style={{textDecoration: 'none'}}>
      <ContentCard {...rest} />
    </Link>
  );
}

LinkedContentCard.propTypes = {
  link: PropTypes.string.isRequired,
  imgUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

LinkedContentCard.defaultProps = {
  imgUrl: 'https://i.imgur.com/ins65DH.jpg',
  title: '',
  subtitle: '',
};

export default LinkedContentCard;