import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import {
  useFirestore,
  useFirestoreDocDataOnce,
  useRemoteConfigString
} from 'reactfire';

import GeneralPageLayout from '../components/GeneralPageLayout';
import NotFound from '../components/NotFound';

const useStyles = makeStyles(theme => ({
  youtubeContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    overflow: 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  }
}))

function SermonPage() {
  const {intro: {imgUrl: defaultImgUrl}} = JSON.parse(useRemoteConfigString('Sermons').data);
  const classes = useStyles();
  const { seriesId, sermonId } = useParams();

  const firestore = useFirestore();

  // Get series from firestore
  const seriesRef = firestore.doc(`playlists/${seriesId}`);
  const seriesSnapshot = useFirestoreDocDataOnce(seriesRef);

  // get video from firestore
  const videoRef = firestore.doc(`playlists/${seriesId}/videos/${sermonId}`);
  const videoSnapshot = useFirestoreDocDataOnce(videoRef);
  
  const series = seriesSnapshot.data;
  const sermon = videoSnapshot.data;
  
  let intro, exists;
  // If the series doesn't exist or has no videos or if the video doesn't exist
  if (series.count && sermon.id) {
    exists = true
    intro = {
      title: sermon.title,
      imgUrl: sermon.imgUrl || series.imgUrl || defaultImgUrl,
      introHeight: 300
    }
  } else {
    exists = false;
    intro = {
      title: 'Sermons',
      imgUrl: defaultImgUrl,
      introHeight: 300
    }  
  }

  return (
    <GeneralPageLayout intro={intro}>
     {
       exists ? 
       <Box className={classes.youtubeContainer} my={4}>
        <YouTube 
          videoId={sermon.id}
          className={classes.iframe}
        />
      </Box> :
      <NotFound type='sermon' />
     }
    </GeneralPageLayout>
  );
}

export default SermonPage;