// Modules
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

// Style
import { makeStyles } from '@material-ui/core';

// Pages
import HomePage from './pages/HomePage';
import InfoAndStoryPage from './pages/InfoAndStoryPage';
import NotFoundPage from './pages/NotFoundPage';
import SermonsLandingPage from './pages/SermonsLandingPage';
import EventsPage from './pages/EventsPage';
import MeetingTimes from './pages/MeetingTimes';
import LogInPage from './pages/LogInPage';
import AdminPage from './pages/AdminPage';
import ConstitutionPage from './pages/ConstitutionPage';

// Sections
import Header from './components/Header';
import Footer from './components/Footer';

// Firebase setup
import {
  useFunctions,
  useRemoteConfig,
  AuthCheck,
} from 'reactfire';

// Getting data and setting state
import { connect } from 'react-redux';
import { setCurrentEvents } from './redux/events/events.actions';

import localContent from './data/content';
import SeriesPage from './pages/SeriesPage';
import SermonPage from './pages/SermonPage';
import ContactPage from './pages/ContactPage';

const useStyles = makeStyles((theme) => ({
  app: {
    textAlign: 'center',
    height: '100%'
  }
}));

function App({setEvents}) {
  const classes = useStyles(); 

  const functions = useFunctions();
  const remoteConfig = useRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 1000;
  remoteConfig.defaultConfig = localContent;

  useEffect(() => {
    remoteConfig.fetchAndActivate()
    .catch((error) => {
      console.log('There was an error with remoteConfig', error);
    });
  }, [remoteConfig]);
  
  // Get event data and add to state
  useEffect(() => {
    async function fetchEvents() {
      const getEvents = functions.httpsCallable('getEvents');
      const response = await getEvents(); 
      const events = response.data;
      setEvents(events);
    }
    fetchEvents();
  }, [functions, setEvents]);

  return (
    <div className={classes.app}>
      <Header />
      <Switch>
        <Route exact path={['/', '/home']} component={HomePage} />
        <Route exact path={['/about', '/about/who-we-are']}>
          <InfoAndStoryPage configString={'About'} />
        </Route>
        <Route exact path={'/about/what-we-believe'}>
          <InfoAndStoryPage configString={'WhatWeBelieve'} />
        </Route>
        <Route exact path={'/about/meeting-times'} component={MeetingTimes} />
        <Route exact path={'/about/constitution'} component={ConstitutionPage} />
        <Route exact path='/sermons' component={SermonsLandingPage} />
        <Route exact path='/sermons/:seriesId' component={SeriesPage} />
        <Route exact path='/sermons/:seriesId/:sermonId' component={SermonPage} />
        <Route exact path='/events' component={EventsPage} />
        <Route exact path='/about/contact' component={ContactPage} />
        <Route exact path='/admin'>
          <AuthCheck fallback={<LogInPage />}>
            <AdminPage />
          </AuthCheck>
        </Route>
        <Route path='*'>
          <NotFoundPage type='page' />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  setEvents: events => dispatch(setCurrentEvents(events)),
});

export default connect(null, mapDispatchToProps)(App);