import React from 'react';

import { useRemoteConfigString } from 'reactfire';

import GeneralPageLayout from '../components/GeneralPageLayout';
import Times from '../components/Times';

function MeetingTimes() {
  const {intro} = JSON.parse(useRemoteConfigString('MeetingTimes').data);
  const {locationsAndMeetingTimes: {times}} = JSON.parse(useRemoteConfigString('Home').data);
  return (
    <GeneralPageLayout intro={intro}>
      <Times times={times} />
    </GeneralPageLayout>
  );
}

export default MeetingTimes;