import React from 'react';
import GeneralPageLayout from '../components/GeneralPageLayout';
import NotFound from '../components/NotFound';

function NotFoundPage({type}) {
  return (
    <GeneralPageLayout>
      <NotFound type={type} />
    </GeneralPageLayout>
  );
}

export default NotFoundPage;