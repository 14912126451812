import React from 'react';
import Markdown from 'markdown-to-jsx';
import LetteredList from '../markdown-replacements/LetteredList';

import options from '../data/markdown-options';

function StyledMarkdown({children, letteredList=false}) {
  if (letteredList) {
    options.overrides.ol = {component: LetteredList};
  }
  
  return (
    <Markdown options={options}>{children}</Markdown>
  );
}

export default StyledMarkdown;