import React, { Fragment } from 'react';
import PageIntro from '../components/PageIntro';
import ContentLayoutGrid from './ContentLayoutGrid';

function GeneralPageLayout({intro, children}) {
  return (
    <Fragment>
      <PageIntro {...intro}/>
      <ContentLayoutGrid>
        {children}
      </ContentLayoutGrid>
    </Fragment>
  );
}

export default GeneralPageLayout;