import React from 'react';
import { Box, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';


const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: 'black',
    height: 250,
    paddingTop: 50,
  },
  button: {
    color: 'white',
    padding: 0,
  },
  a: {
    textDecoration: 'none',
    color: 'white'
  },
  divider: {
    borderColor: 'white',
  },
  text: {
    color: 'white'
  }
}));

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container justify='center'>
        <Grid item xs={3}>
          <Box borderRight={1} className={classes.divider}>
            <a href='tel:7163535471' className={classes.a}>
              <IconButton 
                className={classes.button} 
                aria-label="call picture"
              >
                <Grid container direction='column' >
                  <Grid item>
                    <CallIcon fontSize='large'/>
                  </Grid>
                  <Grid item>
                    <Typography variant='overline'>Call</Typography>
                  </Grid>
                </Grid>
              </IconButton>
            </a>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box borderRight={1} className={classes.divider}>
            <a href='mailto:pastor@rushfordbaptist.org' className={classes.a}>
              <IconButton 
                className={classes.button} 
                aria-label="email picture"
              >
                <Grid container direction='column' >
                  <Grid item>
                    <EmailOutlinedIcon fontSize='large'/>
                  </Grid>
                  <Grid item>
                    <Typography variant='overline'>Email</Typography>
                  </Grid>
                </Grid>
              </IconButton>
            </a>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <a 
              href={`https://www.google.com/maps/dir/?api=1&destination=Rushford+Baptist+Church+Rushford+NY`}
              target='_blank' 
              rel='noreferrer'
              className={classes.a}
            >
              <IconButton 
                className={classes.button} 
                aria-label="call picture"
              >
                <Grid container direction='column' >
                  <Grid item>
                    <RoomOutlinedIcon fontSize='large'/>
                  </Grid>
                  <Grid item>
                    <Typography variant='overline'>Map</Typography>
                  </Grid>
                </Grid>
              </IconButton>
            </a>
          </Box>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Grid container direction='column'>
          <Typography variant='body1' className={classes.text}>Rushford Baptist Church</Typography>
          <Typography variant='body1' className={classes.text}>PO Box 26, 9016 Main Street</Typography>
          <Typography variant='body1' className={classes.text} gutterBottom>Rushford, NY 14777</Typography>
          <Typography variant='body1' className={classes.text}>
            <a href='tel:7163535471' className={classes.a}>716-353-5471</a> | <a href='tel:585-437-2902' className={classes.a}>585-437-2902</a> | <a href='mailto:pastor@rushfordbaptist.org' className={classes.a}>pastor@rushfordbaptist.org</a>
          </Typography>
        </Grid>
      </Box>
      
    </div>
  );
}

export default Footer