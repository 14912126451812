import React from 'react';

import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core';

function TimeCard({title, day, time, location}) {
  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h4" component='h4'>
          {title}
        </Typography>
        <Divider />
        <Box pt={2}>
          <Typography variant="h5" gutterBottom>
            {day}: {time}
          </Typography>
          <Typography variant='body1' gutterBottom>{location}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default TimeCard;