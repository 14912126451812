import React from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  makeStyles, 
  Typography 
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { nanoid } from 'nanoid';

import StyledMarkdown from './StyledMarkdown';

const useStyles = makeStyles({
  primaryContainer: {
    textAlign: 'left',
    width: '100%',
  },
  sectionsHolder: {
    display: 'block',
  },
})

/**
 * 
 * @param {articles} param0 The passed in object must contain a key 'articles' that
 * points to an array of objects each referencing an article in the church constitution.
 * @returns The church constitution rendered using nested Accordions.
 */
function Constitution({articles}) {
  const classes = useStyles();
  return (
    <div className={classes.primaryContainer}>
      {articles.map((article) => (
        <Accordion key={nanoid()} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h5'>{article.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.sectionsHolder}>
            {
              article.sections.length > 1 ?
              article.sections.map(section => (
                <Accordion key={nanoid()} >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='h6'>{section.heading}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <StyledMarkdown letteredList>{section.content}</StyledMarkdown>
                  </AccordionDetails>
                </Accordion>
              )) :
              <StyledMarkdown letteredList>{article.sections[0].content}</StyledMarkdown>
            }
          </AccordionDetails>
        </Accordion>
      ))}    
    </div>
  );
}

export default Constitution;