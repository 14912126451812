import React from 'react';
import { Grid } from '@material-ui/core';
import TimeCard from './TimeCard';

function Times({times}) {
  return (
    <Grid item container justify='center' spacing={4}>
      {
        times.map(time => (
          <Grid item xs={12} sm={6} md={4} key={time.title}>
            <TimeCard {...time} />
          </Grid>
        ))
      }
    </Grid>
  );
}

export default Times;