import React, { Fragment } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { nanoid } from 'nanoid';

function ContentLayoutGrid({children}) {
  return (
    <Grid container direction='row'>
      <Grid item xs={false} md={1} lg={2} />
      <Grid item xs={12} md={10} lg={8}>
        <Box px={4}>
          <Grid container direction='column'> 
            {
              Array.isArray(children) ?
              children.map(child => (
                <Fragment key={nanoid()}>
                  <Grid item xs={12}>
                    {child}
                  </Grid>
                  <Divider />
                </Fragment>
              )) : 
              children
            }
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={false} md={1} lg={2} />
    </Grid>
  );
}

export default ContentLayoutGrid;