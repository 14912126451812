import React from 'react';
import { Box, Grid } from '@material-ui/core';

import LinkedContentCard from './LinkedContentCard';

function CardLayout({contentArray}) {
  return (
    <Box py={4}>
      <Grid container justify='center' spacing={2}>
        {contentArray.map(contentItem => (
          <Grid key={contentItem.id} item xs={12} sm={6} lg={4} xl={3} >
            <LinkedContentCard 
              link={contentItem.link}
              imgUrl={contentItem.imgUrl}
              title={contentItem.title} 
              subtitle={contentItem.subtitle}
            />
          </Grid> 
        ))}
      </Grid>
    </Box>
  );
}

export default CardLayout;