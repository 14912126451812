import React from 'react';
import { useAuth, useFirebaseApp } from 'reactfire';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import GeneralPageLayout from '../components/GeneralPageLayout';

function LogInPage({intro}) {
  const auth = useAuth();
  const firebase = useFirebaseApp().firebase_;

  // See https://github.com/firebase/firebaseui-web#configuration for docs
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    
    // Readd the following code if I decide to create a users collection in Firestore
    // callbacks: {
    //   signInSuccessWithAuthResult: async function(authResult) {
    //     console.log('signed in successfully!');
    //     var user = authResult.user;
    //     const firestoreUserRef = firestore.collection('users').doc(user.uid);

    //     If we don't have the user stored in Firestore
    //     if (!(await firestoreUserRef.get()).exists) {
    //       await firestoreUserRef.set({
    //         createdOn: new Date(),
    //         displayName: user.displayName,
    //         email: user.email,
    //       })
    //     }

    //     return false;
    //   }
    // },
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ]
  };

  return (
    <GeneralPageLayout intro={intro}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </GeneralPageLayout>
  );
}

export default LogInPage;