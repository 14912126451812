import React, {useEffect, useRef} from 'react';
import ReactDOMServer from 'react-dom/server';
import { Loader } from '@googlemaps/js-api-loader';
import { makeStyles } from '@material-ui/core';
import LocationInfoWindow from './LocationInfoWindow';

const useStyles = makeStyles(theme => ({
  map: {
    height: 'min(700px, 60vw)',
  }
}));

function MapContainer({ locations }) {
  const googlemap = useRef(null);
  const classes = useStyles();

  const mapStyles = [
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        { visibility: "off" }
      ]
    }
  ];

  const options={
    center: getCentroid(locations),
    zoom: 13,
    styles: mapStyles
  }

  /* Given an array of location objects, {lat: num, lng: num} 
   * returns an object containing the latitude and longitude of 
   * the centroid of these locations.
   **/
  function getCentroid( locs ) {
    let [latSum, lngSum] = [0, 0];
    
    for ( var loc of locs ) {
      latSum += loc.coords.lat;
      lngSum += loc.coords.lng;
    }

    return {lat: latSum/locs.length, lng: lngSum/locs.length};
  }
  
  useEffect(() => {
    let map;

    function initMap() {
      const google = window.google;
      map = new google.maps.Map(googlemap.current, {
        options
      });

      const infoWindow = new google.maps.InfoWindow();

      for (var location of locations) {
        var marker = new google.maps.Marker({
          position: location.coords,
          map: map,
          title: location.title,
        });
        google.maps.event.addListener(marker, 'click', (function(map, marker, location) {
          return function() {
            infoWindow.setContent(ReactDOMServer.renderToString(<LocationInfoWindow location={location} />));
            infoWindow.open(map, marker);
          }
        })(map, marker, location));

        map.addListener('click', () => {
          if (infoWindow) {
            infoWindow.close();
          }
        })
      }
    }
    
    if (window.google) {
      initMap();
    } else {
      const loader = new Loader({
        apiKey: 'AIzaSyA8U-A3TEpUXc4N0Sd3_fExPG63ePrl4E8',
      });

      loader.load().then(() => {
        initMap();
      });
    }
  });

  return (
    <div id="map" ref={googlemap} className={classes.map} />
  );
}

export default MapContainer;