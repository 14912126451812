import React, { Fragment, useState } from 'react';

import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(theme => (
  {
    sublist: {
      paddingLeft: theme.spacing(5),
    }
  }
))

function NavigationDrawerItem({title, path, children, clickHandler}) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  return (
      children ?
      (
        <Fragment>
          <ListItem button onClick={toggleExpanded}>
            <ListItemText primary={title} />
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                children.map(child => (
                  <ListItem className={classes.sublist} button key={title+child.title} component={Link} to={path+child.path} onClick={clickHandler}>
                    <ListItemText primary={child.title} />
                  </ListItem>
                ))
              }
            </List>
          </Collapse>
        </Fragment>
      ) :
      (
        <ListItem button key={title} component={Link} to={path} onClick={clickHandler}>
          <ListItemText primary={title} />
        </ListItem>
      )
  );
}

export default NavigationDrawerItem;