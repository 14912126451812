import React, { Fragment, useState } from 'react';

import { Drawer, IconButton, List, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NavigationDrawerItem from './NavigationDrawerItem';
import { useRemoteConfigString } from 'reactfire';

const useStyles = makeStyles(theme => ({
  hamburger: {
    color: 'white'
  },
  list: {
    width: 200,
  }
}))

function NavigationDrawer() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const directory = JSON.parse(useRemoteConfigString('MenuDirectory').data);

  function toggleDrawer() {
    setDrawerOpen( !drawerOpen );
  };

  return (
    <Fragment>
      <IconButton className={classes.hamburger} onClick={toggleDrawer}>
        <MenuIcon fontSize='large' />
      </IconButton>
      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <List className={classes.list}>
          {directory.map(page => (
            <NavigationDrawerItem key={page.title} {...page} clickHandler={toggleDrawer}></NavigationDrawerItem>
          ))}
        </List>
      </Drawer>
    </Fragment>
  );
}

export default NavigationDrawer;