import React from 'react';

import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    border: '3px solid white',
    color: 'white',
    borderRadius: '0',
  }
}));

function PageIntroActionButton({path, children}) {
  const classes = useStyles();

  return (
    <Button 
      className={classes.button} 
      component={Link} 
      to={path}
      variant='outlined'
      size='large'
    >
      {children}
    </Button>
  );
}

export default PageIntroActionButton;