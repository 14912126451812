import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardContent, CardMedia, Link, Typography } from '@material-ui/core';
import { getSizedUrl } from '../helper-functions';

const useStyles = makeStyles(theme => ({
  card: {

  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
}));

function LocationInfoWindow({location: {imgUrl, title, address1, address2}}) {
  const classes = useStyles();

  imgUrl = getSizedUrl(imgUrl, 'm')

  return (
    <div>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={imgUrl}
          title={`${title} image`}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component='h5'>
            {title}
          </Typography>
          <Typography variant="body1" component='p' gutterBottom>
            {address1}<br />{address2}
          </Typography>
          <Button 
            color='primary'
            variant='contained'
            component={Link}
            href={`https://www.google.com/maps/dir/?api=1&destination=${title.replaceAll(' ', '+')}+Rushford+NY`}
            target='_blank'
          >
            Get Directions
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}

export default LocationInfoWindow;