import React from 'react';
import { Card, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  media: {
    height: 250,
  },
  content: {
    padding: theme.spacing(1),
    '&:last-child': {
      padding: 8
    }
  }
}));

function ContentCard({title, imgUrl, subtitle}) {
const classes = useStyles();

  return (
    <Card raised square>
      <CardMedia className={classes.media} image={imgUrl} title={title} />
      <CardContent className={classes.content}>
        <Typography variant="h5" component='h5'>
          {title}
        </Typography>
        <Typography variant="subtitle1" component='p'>
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

ContentCard.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  subtitle: PropTypes.string,
};

ContentCard.defaultProps = {
  title: '',
  imgUrl: 'https://i.imgur.com/TcpcxEo.jpg',
  subtitle: ''
};

export default ContentCard;