import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Box, Card, CardMedia, Grid, makeStyles, useMediaQuery, Typography } from '@material-ui/core';
import StyledMarkdown from '../components/StyledMarkdown';
import { getSizedUrl } from '../helper-functions';

const useStyles = makeStyles(theme => ({
  story: {
    textAlign: 'left'
  },
  wrappingHolder: {
    width: '50%',
  },
  media: {
    paddingTop: '100%',
  },
}))

function StorySection({title, imgUrl, content, leftImage}) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  imgUrl = getSizedUrl(imgUrl, 'm');

  const image = <Card raised>
    <CardMedia className={classes.media} image={imgUrl} title={title} />
  </Card>;

  const text = <StyledMarkdown>{content}</StyledMarkdown>;

  return (
    <Box py={4} px={4}>
      <Typography variant='h2' gutterBottom>{title}</Typography>
      {
        xs ? 
        <Grid container direction={leftImage ? 'column' : 'column-reverse'} spacing={2}>
          <Grid item style={{marginBottom: '100%'}}>
            {image}
          </Grid>
          <Grid item>
            {text}
          </Grid>
        </Grid> :
        <Box className={classes.story}>
          <Box 
            className={classes.wrappingHolder} 
            style={{
              float: leftImage ? 'left' : 'right',
            }}
            mr={leftImage ? 2 : 0}
            mb={1}
            ml={leftImage ? 0 : 2}
          >
            {image}
          </Box>
          {text}
        </Box>
      }
    </Box>
  );
}

export default StorySection;