import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import firebase from 'firebase/app';
import 'firebase/performance';
import { 
  FirebaseAppProvider,
  preloadAuth,
  preloadFirestore,
  preloadFunctions,
  preloadRemoteConfig,
  SuspenseWithPerf,
 } from 'reactfire';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import store from './redux/store';

fetch('/__/firebase/init.json').then(async (response) => {
  const firebaseConfig = await response.json()
  const firebaseApp = firebase.initializeApp(firebaseConfig)
  if (process.env.NODE_ENV !== 'production') {
    await preloadAuth({
      firebaseApp,
      setup: (factory) => {
        const auth = factory()
        auth.useEmulator('http://localhost:9099')
        return auth
      },
    });
    
    await preloadFirestore({
      firebaseApp,
      setup: (factory) => {
        const firestore = factory();
        firestore.useEmulator('localhost', 8080);
        return firestore;
      },
    });
  
    await preloadFunctions({
      firebaseApp,
      setup: (factory) => {
        const functions = factory();
        functions.useEmulator('localhost', 5001);
        return functions;
      },
    });
  } else 
  {
    preloadFirestore({
      firebaseApp,
      setup: (factory) => {
        const firestore = factory();
        firestore.enablePersistence({ synchronizeTabs: true })
        return firestore;
      }
    });
    preloadFunctions({
      firebaseApp,
      setup: (factory) => {
        const functions = factory();
        return functions;
      }
    });
  }
  preloadRemoteConfig({
    firebaseApp,
    setup: (factory) => {
      const remote = factory();
      return remote;
    }
  })
  
  ReactDOM.unstable_createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <Provider store={store}>
        <FirebaseAppProvider firebaseApp={firebaseApp} initPerformance suspense >
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <SuspenseWithPerf fallback={'loading'} traceId={'initial-load'}>
                <App />
              </SuspenseWithPerf>
            </ThemeProvider>
          </BrowserRouter>
        </FirebaseAppProvider>
      </Provider>
    </React.StrictMode>
  );
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
