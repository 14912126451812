import React, { Fragment } from 'react';

import GeneralPageLayout from '../components/GeneralPageLayout';
import { Button } from '@material-ui/core';
import { useAuth, useIdTokenResult, useFunctions, useUser } from 'reactfire';

function AdminScreeningPage({intro}) {
  const auth = useAuth();
  const { data: user } = useUser();
  const { data: {claims: {admin}} } = useIdTokenResult(user);
  const functions = useFunctions();

  const signOut = async () => {
    try {
      await auth.signOut()
    } catch(error) {
      console.log('Failed to sign out.');
      console.log(error);
    };
  }

  const getYoutubeVideos = async () => {
    try {
      const getVideos = functions.httpsCallable('getYoutubeVideos');
      const response = await getVideos();
      console.log(response.data);
    } catch(error) {
      console.log('Failed to getVideos.');
      console.log(error);
    };
  }

  return (
    <GeneralPageLayout intro={intro}>
      {
        admin ?
        // to replace with admin console
        <Fragment>
          {`${user.displayName}, you are logged in as an admin`}
          <Button onClick={signOut}>Sign out</Button>
          <Button onClick={getYoutubeVideos}>Get Videos</Button>
        </Fragment> :
        <Fragment>
          {`You are logged in as ${user.email} and do not have admin priviledges on this account.`}
          <Button onClick={signOut}>Sign out</Button>
        </Fragment>
      }
    </GeneralPageLayout>
  )
}

export default AdminScreeningPage