import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  ol: {
    listStyleType: 'upper-alpha',
    '& ol': {
      listStyleType: 'decimal',
    }
  }
}));

function LetteredList({children}) {
  const classes = useStyles();

  return (
    <Typography variant='body1' component='ol' className={classes.ol}>{children}</Typography>
  );
}

export default LetteredList;