import React from 'react';
import { useFirestore, useFirestoreCollectionData, useRemoteConfigString } from 'reactfire';

import CardLayout from '../components/CardLayout';
import GeneralPageLayout from '../components/GeneralPageLayout';

function SermonsLandingPage() {
  const {intro} = JSON.parse(useRemoteConfigString('Sermons').data);

  const firestore = useFirestore();
  const playlistsRef = firestore.collection('playlists');
  const playlistSnapshots = useFirestoreCollectionData(playlistsRef);
  const playlists = playlistSnapshots.data.sort((a, b) => (a.startDate > b.startDate) ? -1 : 1);

  const cardInfo = [];
  for (const playlist of playlists) {
    const {id, thumbnails: {standard: {url=''} = {url: ''}, high: {url: highUrl} = {url: ''}}, title, count, imgUrl } = playlist;
    cardInfo.push({
      id: id,
      link: `/sermons/${id}`,
      imgUrl: imgUrl || url || highUrl || intro.imgUrl,
      title: title.slice(10),
      subtitle: count.toString() + ' Messages'
    })
  }

  return (
    <GeneralPageLayout intro={intro}>
      <CardLayout contentArray={cardInfo} />
    </GeneralPageLayout>
  );
}

export default SermonsLandingPage;