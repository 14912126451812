import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import EventCarousel from './EventCarousel';
import { areEvents } from '../redux/events/events.selectors';
import { connect } from 'react-redux';

function EventsSection({areEvents}) {
  return (
    areEvents ? 
    <Box py={4}>
      <Grid container direction='column' justify='center'>
        <Grid item>
          <Typography variant='h2'>Upcoming Events</Typography>
        </Grid>
        <Grid container item justify='center'>
          <EventCarousel />
        </Grid>
      </Grid>
    </Box> :
    null
  );
}

const mapStateToProps = state => ({
  areEvents: areEvents(state),
});

export default connect(mapStateToProps)(EventsSection);